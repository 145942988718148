<template>
  <div
    class="mx-auto grid w-fit grid-cols-2 gap-3 md:mx-0 md:gap-x-4 md:gap-y-5 lg:grid-cols-4"
  >
    <component
      :is="getLink(item)"
      v-for="(item, index) in OUR_DIVISIONS"
      :key="index"
      no-prefetch
      v-bind="props(item.title, item.url)"
      class="service-link max-w-[160px] md:max-w-full"
    >
      <Package
        class="bg-grey-100 md:bg-grey-50 h-full w-full pb-4 pt-6 shadow-none md:py-10"
        :service="item"
        content-classes="!font-normal"
        variant="simple"
        data-testid="ourDivision-item"
      >
        <template #default="">
          <h3
            class="flex items-center justify-between text-sm font-semibold md:mb-3 md:justify-start md:font-bold xl:text-lg 2xl:text-2xl"
          >
            {{ item.title }}
            <Icon name="angle-right" class="icon-arrow flex-shrink-0" />
          </h3>

          <span class="hidden md:block 2xl:text-base">
            {{ item.description }}
          </span>
        </template>
      </Package>
    </component>
  </div>
</template>

<script setup lang="ts">
import Icon from '~/components/common/Icon.vue'
import Package from '~/components/shared/services/Package.vue'
import { OUR_DIVISIONS } from '~/constants/services/constants'

const { $config } = useNuxtApp()

function isBenchmarkSource(title) {
  return title === 'Benchmark Source'
}
function getLink(item) {
  return isBenchmarkSource(item.title) ? 'a' : resolveComponent('NuxtLink')
}
function props(title, url) {
  return isBenchmarkSource(title)
    ? { href: $config.public.sourceUrl, target: '__blank' }
    : { to: url }
}
</script>

<style lang="scss" scoped>
.icon-arrow {
  transition: transform 0.1s ease-in-out;
}
.service-link:hover .icon-arrow {
  transform: translateX(4px);
}
</style>
